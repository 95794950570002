<script>
  import { get, call } from 'vuex-pathify'

  export default {
    props: {
      /** Whether the CTA button at the bottom of the form should be hidden */
      hideButton: {
        type: Boolean,
        default: false,
      },
      /** Whether billing address is same as shipping. If true, the billing (first name, last name and postal code) fields should be hidden */
      billingSameAsShipping: {
        type: Boolean,
        default: false,
      },
      /** Used for when you need to hide/display certain fields in the subscribe flow based on what method the user selects
       * (e.g., hide credit card fields but display discount fields if user selects paypal) */
      selectedPaymentMethod: {
        type: String,
        default: undefined,
        validator: (value) => ['credit-card', 'paypal'].includes(value),
      },
      /** Whether the gift card and coupon fields should be displayed */
      showDiscountFields: {
        type: Boolean,
        default: false,
      },
      /** Whether the discount fields are disabled (e.g., in subscribe flow, shipping address needs to be filled out first) */
      discountFieldsDisabled: {
        type: Boolean,
        default: false,
      },
      /** Whether there is a dropdown to select an existing address */
      selectOption: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        formRef: null,
        card: {
          firstName: '',
          lastName: '',
          zipCode: '',
          number: '',
          expirationDate: '',
          securityCode: '',
        },
        cardNumberInputRef: null,
        securityCodeHelpPanelVisible: false,
        securityCodeHelpButtonRef: null,
        zipCodeInputRef: null,
        expirationDateInputRef: null,
        couponCode: this.$store.get('couponAndGiftCard/submittedCouponCode') ?? '',
        giftCard: this.$store.get('couponAndGiftCard/submittedGiftCard') ?? '',
        giftCardHelpButtonRef: null,
        giftCardHelpPanelVisible: false,
        billingOption:
          this.$store.get('subscribe/selectedPaymentMethod')?.handle === 'credit-card'
            ? 'default'
            : null,
      }
    },
    computed: {
      ...get('account', ['billingInfo']),
      ...get('config', ['countries', 'isCurrentBoxWaitlist']),
      ...get('feature', ['flags']),
      ...get('subscribe', ['selectedPlan', 'shippingAddress', 'hasExistingBillingInfo']),
      ...get('couponAndGiftCard', [
        'submittedCouponCode',
        'submittedCouponCodeIsValid',
        'submittedCouponCodeIsInvalid',
        'submittedCouponCodeWasValidated',
        'submittedCouponCodeIsBeingValidated',
        'submittedGiftCard',
        'submittedGiftCardIsValid',
        'submittedGiftCardIsInvalid',
        'submittedGiftCardWasRedeemed',
        'submittedGiftCardWasValidated',
        'submittedGiftCardIsBeingValidated',
      ]),
      isReferral() {
        return this.flags['referral-rewards'] && this.$cookies.isKey('talkableFriendReferral')
      },
      showCouponCode() {
        return !this.isReferral && !this.isCurrentBoxWaitlist
      },
      isIntroBoxEnabled() {
        return this.flags['intro-box'] ?? false
      },
      isInSubscribeFlow() {
        return this.$route.name === 'SubscribeSignup'
      },
      forcedCouponCode() {
        if (this.isIntroBoxEnabled) {
          // Note: these also need to be updated in the API
          return 'GATHER'
        }
        return ''
      },
      securityCodeName() {
        return this.cardNumberInputRef?.securityCodeName
      },
      securityCodeLength() {
        return this.cardNumberInputRef?.securityCodeLength
      },
      securityCodeMinLength() {
        return this.securityCodeLength ?? 3
      },
      securityCodeMaxLength() {
        return this.securityCodeLength ?? 4
      },
      selectedCountryIso() {
        return this.billingSameAsShipping
          ? this.countries?.find((country) => country.id === this.shippingAddress.countryId)?.iso
          : undefined
      },
      billingInfoOptions() {
        return [
          {
            label: `Card on file: ${this.billingInfo.cardNumber}`,
            value: 'default',
          },
          {
            label: 'Change payment method',
            value: 'new',
          },
        ]
      },
      payPalSelected() {
        return this.selectedPaymentMethod === 'paypal'
      },
      hasValidationErrors() {
        const validation = this.formRef?.validationProviders
          ? Object.values(this.formRef?.validationProviders).filter(
              (provider) => provider?.failedRules && Object.keys(provider.failedRules).length > 0
            ).length
          : 0
        return validation
      },
    },
    watch: {
      billingOption() {
        this.$store.set('subscribe/useExistingBillingInfoIfAny', this.billingOption !== 'new')
      },
      submittedCouponCode: {
        immediate: true,
        async handler() {
          if (this.submittedCouponCode) {
            await this.$waitForValue(() => this.submittedCouponCodeWasValidated, true)
            await this.$waitForValue(() => this.formRef?.validationProviders?.couponCode)
            if (this.submittedCouponCodeIsInvalid) {
              this.formRef.validationProviders.couponCode.setErrors([
                'This coupon code is not valid.',
              ])
            }
          }
          if (this.refreshingSubscriptionPreview) {
            this.calculatingDiscount = true
          }
        },
      },
      submittedGiftCard: {
        immediate: true,
        async handler() {
          if (this.submittedGiftCard) {
            await this.$waitForValue(() => this.submittedGiftCardWasValidated, true)
            await this.$waitForValue(() => this.formRef?.validationProviders?.giftCard)
            if (this.submittedGiftCardIsInvalid) {
              this.formRef.validationProviders.giftCard.setErrors(['This gift card is not valid.'])
            } else if (this.submittedGiftCardWasRedeemed) {
              this.formRef.validationProviders.giftCard.setErrors([
                'This gift card has already been redeemed.',
              ])
            }
          }
          if (this.refreshingSubscriptionPreview) {
            this.calculatingCredit = true
          }
        },
      },
      hasValidationErrors() {
        if (this.hasValidationErrors <= 0) {
          this.processForm()
        }
      },
    },
    async mounted() {
      // Set Coupon if intro box is enabled and we're in the subscribe flow
      if (this.isInSubscribeFlow && this.isIntroBoxEnabled && this.forcedCouponCode) {
        if (this.hasExistingBillingInfo) {
          await this.submitCouponCode(this.forcedCouponCode)
        } else {
          this.couponCode = this.forcedCouponCode
          this.$store.set('couponAndGiftCard/submittedCouponCode', this.forcedCouponCode)
        }
      }
    },
    methods: {
      ...call('couponAndGiftCard', {
        doSubmitCouponCode: 'submitCouponCode',
        doSubmitGiftCard: 'submitGiftCard',
      }),
      async submitCouponCode(couponCode) {
        this.couponCode = couponCode.toUpperCase()
        await this.doSubmitCouponCode({
          couponCode: this.couponCode,
          area: 'subscribe',
        })
      },
      async submitGiftCard(giftCard) {
        this.giftCard = giftCard.toUpperCase()
        await this.doSubmitGiftCard({
          giftCard: this.giftCard,
          area: 'subscribe',
        })
      },
      // consider renaming this as the form isn't really submitted
      async processForm() {
        const billingValidated = await this.formRef.validate()
        if (this.isInSubscribeFlow && billingValidated) {
          this.submit()
        }
        return billingValidated
      },
      submit() {
        const recurlyPayload = {
          first_name: this.billingSameAsShipping
            ? this.shippingAddress.firstName
            : this.card.firstName,
          last_name: this.billingSameAsShipping
            ? this.shippingAddress.lastName
            : this.card.lastName,
          postal_code: this.billingSameAsShipping
            ? this.shippingAddress.zipCode
            : this.card.zipCode,
          number: this.card.number,
          month: this.expirationDateInputRef.month,
          year: this.expirationDateInputRef.year,
          cvv: this.card.securityCode,
          country: this.billingSameAsShipping
            ? this.selectedCountryIso
            : this.zipCodeInputRef.finalCountryIso,
        }
        this.$emit('submit', recurlyPayload)
      },
      async process(processingPromise) {
        return this.formRef.process(processingPromise)
      },
      async refreshCardNumber() {
        if (this.hasValidationErrors) {
          await this.processForm()
        }
      },
    },
  }
</script>

<template>
  <BaseForm
    v-slot="{ inputsEnabled, submitEnabled, errorMessage }"
    v-ref="formRef"
    spinner
    @submit="submit"
  >
    <BaseHeading v-if="$scopedSlots.default" size="h5" tag="h2" class="mb-6">
      <slot />
    </BaseHeading>

    <BasePanelWarning v-if="errorMessage" class="mb-6">
      {{ errorMessage }}
    </BasePanelWarning>
    <BaseStack>
      <BaseInput
        v-if="!payPalSelected && selectOption && billingInfo.type === 'credit-card'"
        v-model="billingOption"
        type="select"
        name="billingInfo"
        :options="billingInfoOptions"
        :disabled="!inputsEnabled"
        required
      >
        Payment method
      </BaseInput>
      <BaseStack>
        <template
          v-if="(selectOption && billingOption === 'new') || (!payPalSelected && !selectOption)"
        >
          <div v-if="!billingSameAsShipping" class="flex">
            <BaseInput
              v-model="card.firstName"
              type="text"
              :disabled="!inputsEnabled"
              required
              class="w-1/2"
              v-on="$listeners"
            >
              First Name
            </BaseInput>
            <BaseInput
              v-model="card.lastName"
              type="text"
              :disabled="!inputsEnabled"
              required
              class="w-1/2 ml-2"
              v-on="$listeners"
            >
              Last Name
            </BaseInput>
          </div>
          <BaseInputCreditCard
            v-model="card.number"
            v-ref="cardNumberInputRef"
            :disabled="!inputsEnabled"
            required
            data-dd-privacy="mask"
            @blur="refreshCardNumber"
            v-on="$listeners"
          />
          <div class="flex">
            <BaseInputExpirationDate
              v-model="card.expirationDate"
              v-ref="expirationDateInputRef"
              :disabled="!inputsEnabled"
              required
              data-dd-privacy="mask"
              class="w-1/2"
              v-on="$listeners"
            />
            <BaseInput
              v-model="card.securityCode"
              type="tel"
              :rules="{ numeric: true }"
              :min-length="securityCodeMinLength"
              :max-length="securityCodeMaxLength"
              :disabled="!inputsEnabled"
              required
              data-dd-privacy="mask"
              class="w-1/2 ml-2"
              @blur="processForm"
              v-on="$listeners"
            >
              CVV
              <template v-slot:icons>
                <BaseIconInput
                  v-ref="securityCodeHelpButtonRef"
                  label="Help"
                  @click="securityCodeHelpPanelVisible = !securityCodeHelpPanelVisible"
                >
                  <IconHelp />
                </BaseIconInput>
              </template>
            </BaseInput>
          </div>
          <div class="-mb-2">
            <BaseSlideUpDown :expanded="securityCodeHelpPanelVisible">
              <div class="pt-2 pb-3">
                <BasePanel :arrow-anchor="securityCodeHelpButtonRef">
                  Your card’s security code
                  {{ securityCodeName ? ' (' + securityCodeName + ')' : '' }} is the
                  {{ securityCodeLength ? securityCodeLength : '3 or 4' }} digit number located on
                  the back of most cards.
                </BasePanel>
              </div>
            </BaseSlideUpDown>
          </div>
          <BaseInputPostalCode
            v-if="!billingSameAsShipping"
            v-model="card.zipCode"
            v-ref="zipCodeInputRef"
            :disabled="!inputsEnabled"
            required
            @blur="processForm"
            v-on="$listeners"
          >
            Billing Postal Code
          </BaseInputPostalCode>
        </template>

        <template v-if="showDiscountFields">
          <BaseInput
            v-model="giftCard"
            type="text"
            name="giftCard"
            :mask="/^[a-zA-Z0-9-_+]*$/"
            :disabled="!inputsEnabled || discountFieldsDisabled"
            input-classes="uppercase"
            @keydown.enter.prevent="$event.target.blur"
            @change="submitGiftCard(giftCard)"
            v-on="$listeners"
          >
            Gift Card
            <template v-slot:icons>
              <BaseSpinner
                v-if="giftCard === submittedGiftCard && submittedGiftCardIsBeingValidated"
                :size="6"
                class="mr-1"
              />
              <BaseIconInput
                v-if="giftCard === submittedGiftCard && submittedGiftCardIsValid"
                :size="7"
                class="text-primary-bright"
              >
                <IconCheckmark />
              </BaseIconInput>
              <BaseIconInput
                v-ref="giftCardHelpButtonRef"
                label="Help"
                @click="giftCardHelpPanelVisible = !giftCardHelpPanelVisible"
              >
                <IconHelp />
              </BaseIconInput>
            </template>
          </BaseInput>
          <div class="-mb-2">
            <BaseSlideUpDown :expanded="giftCardHelpPanelVisible">
              <div class="pt-2 pb-3">
                <BasePanel :arrow-anchor="giftCardHelpButtonRef">
                  Your payment method will only be used to pay for additional charges not covered by
                  your gift card.
                </BasePanel>
              </div>
            </BaseSlideUpDown>
          </div>
          <div v-if="showCouponCode" class="flex">
            <div class="w-2/3">
              <BaseInput
                v-model="couponCode"
                type="text"
                name="couponCode"
                :mask="/^[a-zA-Z0-9-_+]*$/"
                :disabled="!inputsEnabled || Boolean(forcedCouponCode) || discountFieldsDisabled"
                input-classes="uppercase"
                @keydown.enter.prevent="$event.target.blur"
                @change="submitCouponCode(couponCode)"
                v-on="$listeners"
              >
                Coupon Code
                <template v-slot:icons>
                  <BaseSpinner
                    v-if="couponCode === submittedCouponCode && submittedCouponCodeIsBeingValidated"
                    :size="6"
                    class="mr-1"
                  />
                  <BaseIconInput
                    v-if="couponCode === submittedCouponCode && submittedCouponCodeIsValid"
                    :size="7"
                    class="text-primary-bright"
                  >
                    <IconCheckmark />
                  </BaseIconInput>
                </template>
              </BaseInput>
            </div>
            <div class="ml-2 w-1/3 flex-shrink-0">
              <BaseButton
                outline-only
                :disabled="
                  !inputsEnabled ||
                  !couponCode ||
                  Boolean(forcedCouponCode) ||
                  (couponCode === submittedCouponCode && submittedCouponCodeIsBeingValidated)
                "
                @click="submitCouponCode(couponCode)"
                >Apply</BaseButton
              >
            </div>
          </div>
        </template>
      </BaseStack>
    </BaseStack>

    <BaseButton v-if="!hideButton" type="submit" :disabled="!submitEnabled" class="mt-6">
      <slot name="buttonLabel">Submit</slot>
    </BaseButton>
  </BaseForm>
</template>
