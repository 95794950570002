import loadjs from 'loadjs'
import store from '@/store'

let recurlyPromise = null

const getPublicKey = async () => {
  await store.dispatch('config/ensureFresh')
  return store.get('config/recurlyPublicKey')
}

const initRecurly = async () => {
  if (recurlyPromise === null) {
    recurlyPromise = (async () => {
      const publicKeyPromise = getPublicKey()
      await loadjs('https://js.recurly.com/v4/recurly.js', {
        returnPromise: true,
      })
      const publicKey = await publicKeyPromise
      window.recurly.configure(publicKey)
      return window.recurly
    })()
  }
  return recurlyPromise
}

export const getCreditCardToken = async (cardData) => {
  const publicKey = await getPublicKey()
  const formData = new FormData()
  formData.append('key', publicKey)
  for (const prop in cardData) {
    formData.append(prop, cardData[prop])
  }

  const response = await window.fetch('https://api.recurly.com/js/v1/token', {
    method: 'POST',
    body: formData,
  })

  const responseData = await response.json()
  if (responseData.error) {
    const msg = `There was an error processing your card [${responseData.error.message}]`
    return Promise.reject(new Error(msg))
  }
  return responseData.id
}

export const getPayPalToken = async () => {
  const recurly = await initRecurly()
  return new Promise((resolve, reject) => {
    const paypal = recurly.PayPal({
      display: {
        displayName: 'Alltrue',
      },
    })
    // No need to handle this exception except to turn off the loading
    // indicator. This exception will generally be when the user closes
    // the PayPal modal. The modal will surface any PayPal related
    // errors to the user.
    paypal.on('error', () => resolve(null))
    paypal.on('cancel', () => resolve(null))
    paypal.on('token', (token) => resolve(token.id))
    paypal.start()
  })
}

export const getRecurlyErrorMessage = (errorResponse) => {
  const paymentErrorReasons = [
    'declined',
    'expired card',
    'date does not match',
    'insufficient funds',
    'invalid',
    'billing info is required',
    'card has a temporary hold',
  ]
  const errors = errorResponse?.errors
  let errorMessage = ''

  // array of error messages. remove duplicates
  if (Array.isArray(errors) && errors.length >= 1) {
    errorMessage = [...new Set(errors)].join(' ')
  }

  const isRecurlyError = paymentErrorReasons.some((reason) => {
    return errorMessage.toLowerCase().includes(reason)
  })

  return isRecurlyError ? errorMessage : ''
}
