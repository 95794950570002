<script>
  import { call } from 'vuex-pathify'
  import { ApiError } from '@/api'
  import { getCreditCardToken } from '@/helpers/recurly'
  import BillingCreditCardForm from '@/components/account/BillingCreditCardForm'

  export default {
    components: {
      BillingCreditCardForm,
    },
    data() {
      return {
        formRef: null,
      }
    },
    methods: {
      ...call('account', ['updateBillingInfo']),
      async submit(recurlyPayload) {
        try {
          const saveBillingInfo = async () => {
            const token = await getCreditCardToken(recurlyPayload)
            await this.updateBillingInfo(token)
          }
          await this.formRef.process(saveBillingInfo())
          this.$navigate({ name: 'CheckoutBilling' })
        } catch (error) {
          if (error instanceof ApiError) {
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section>
    <BillingCreditCardForm v-ref="formRef" @submit="submit($event)">
      Enter your credit card information
      <template v-slot:buttonLabel>Change payment method</template>
    </BillingCreditCardForm>

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'CheckoutBilling' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
